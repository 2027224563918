import React from 'react';

import Layout from '../components/Layout';
import UpdatedGames from '../components/UpdatedGames';

const Page = (props) => {
  const { contentIds, ...context } = props.pageContext
  const { data } = props;
  return (
    <Layout {...context}>
      <UpdatedGames days={10} />
    </Layout>
  )
}

export default Page;
